import {
  AddressService,
  JSONConfigService,
  KonakartService,
  PrequalService,
  MyAccountService,
  NavigationService,
  SentryService,
} from '@sky-tv-group/shared';
import { kkAgent, addressAgent, agentJSONConfig, agentPrequal, agentMyAccount, agentNavigation } from '../agent';
import { SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS } from '../config';

export const konakartService = new KonakartService(kkAgent);
export const addressService = new AddressService(addressAgent);
export const configService = new JSONConfigService(agentJSONConfig);
export const prequalService = new PrequalService(agentPrequal);
export const myAccountService = new MyAccountService(agentMyAccount);
export const navService = new NavigationService(agentNavigation);
export const sentryService = new SentryService(SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS);
